import { useContext, useCallback, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Box } from '@material-ui/core';
import { AuthContext } from '../../HigherOrder/AuthController/AuthController';
import { getRowStyle, tableIcons } from '../../Tools';
import { ContactInfo } from '../Agent/AgentContact/AgentContact';
import { useSnackbar } from 'notistack';
import MaterialTable from 'material-table';
import { format } from 'date-fns';

interface Props {
    open: boolean,
    setOpen: (open: boolean) => void
}

export default function ContactDialog({ open, setOpen }: Props) {

    const { push } = useHistory();
    const { userData, optionMap, handleErrors } = useContext(AuthContext);
    const { enqueueSnackbar } = useSnackbar();
    const [contactInfo, setContactInfo] = useState<ContactInfo[]>([]);

    const handleContactRequest = useCallback(async () => {
        if (userData) {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/agents/contact?` + new URLSearchParams({
                auth: userData.auth
            })).then(handleErrors);

            return await response.json() as ContactInfo[];
        }
        return [];
    }, [handleErrors, userData])

    const getContactInfo = useCallback(async () => {
        try {
            const contactInfo = await handleContactRequest();
            setContactInfo(contactInfo);
        } catch (error) {
            console.error(error);
            if (error instanceof Error) {
                enqueueSnackbar(error.message, { variant: 'error' });
            } else {
                enqueueSnackbar('An unexpected error has occurred', { variant: 'error' });
            }
        }
    }, [enqueueSnackbar, handleContactRequest]);

    const hasPermission = optionMap.has('AGTCNT');

    useEffect(() => {
        if (hasPermission) {
            getContactInfo();
        }
    }, [getContactInfo, hasPermission])

    const handleClose = () => {
        localStorage.setItem('lastContactInfoPrompt', format(new Date(), 'yyyyMMdd'));
        setOpen(false);
    }

    const updateContactInfo = () => {
        push('/AGTCNT');
        handleClose();
    }

    return (
        <Dialog onClose={handleClose} open={open && hasPermission} maxWidth='md'>
            <DialogTitle>
                Contact Information
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    We have the following contact information on file for the Agent(s) associated with your account.
                </Typography>
                <Box p={2}>
                    <MaterialTable
                        icons={tableIcons}
                        columns={[
                            { title: 'Agent #', field: 'agent' },
                            { title: 'Contact Name', field: 'contactName' },
                            { title: 'Contact Phone', field: 'contactPhone' },
                            { title: 'Contact Email', field: 'contactEmail' },
                            { title: 'Receive Important Texts', field: 'receiveTexts' }
                        ]}
                        data={contactInfo}
                        isLoading={contactInfo.length === 0}
                        options={{
                            rowStyle: getRowStyle,
                            maxBodyHeight: '110px',
                            minBodyHeight: '110px',
                            paging: false,
                            draggable: false,
                            sorting: false,
                            toolbar: false
                        }} />
                </Box>
                <Typography gutterBottom>
                    Does this information look correct?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Yes
                </Button>
                <Button onClick={updateContactInfo} color="primary">
                    No
                </Button>
            </DialogActions>
        </Dialog>
    );
}