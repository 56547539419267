import { Box, Paper, Typography } from '@material-ui/core';

interface Props {
    overflow?: boolean
}

export default function TermsOfUse({ overflow }: Props) {
    return (
        <Box component={Paper} maxHeight={overflow ? '500px' : null} overflow={overflow ? 'auto' : null} p={4}>
            <Typography align="center" variant="h5">PRIVACY, TERMS OF USE AND LEGAL DISCLAIMER</Typography>
            <Typography align="center" variant="h6">LEGAL DISCLAIMER</Typography>
            <Typography align="left" variant="body1" gutterBottom={true}>
                This Site has been compiled in good faith solely for the purposes of providing information about the services and products
                provided by GSC Enterprises, Inc. ("GSC") and specifically, its Division, Fidelity Express ("Fidelity Express").GSC
                and/or Fidelity Express reserve the right, at its sole discretion, to change any and all information,
                including the Terms of Use provided in this Site at any time and for any reason without any prior notice.
            </Typography>
            <Typography align="left" variant="body1" gutterBottom={true}>
                Without prior written permission from Fidelity Express, any use, reproduction, distribution or broadcast of any information obtained
                on or from this Site is strictly prohibited.Further, no linking to this Site is allowed without prior written permission from Fidelity Express.
            </Typography>
            <Typography align="left" variant="body1" gutterBottom={true}>
                GSC and Fidelity Express strive to remain current on their virus protections and definitions.
                However, you understand and agree that GSC nor Fidelity Express can absolutely guarantee or warrant the
                Site is free from viruses or other destructive devices or processes.You further understand and agree that your access and use is your responsibility.
                You are proceeding at your own risk, and assume and accept all responsibility and liability.
            </Typography>
            <Typography align="left" variant="body1" gutterBottom={true}>
                THE CONTENT IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT ANY WARRANTIES OR GUARANTEES OF ANY KIND,
                EITHER EXPRESS OR IMPLIED.GSC AND FIDELITY EXPRESS DISCLAIM ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO,
                ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE OR NON-INFRINGEMENT.FURTHER,
                GSC AND FIDELITY EXPRESS DO NOT WARRANT THAT THIS SITE WILL OPERATE UNINTERRUPTED OR ERROR-FREE; THAT IT WILL BE RELIABLE,
                UPDATED OR CORRECTED ON A TIMELY BASIS; THAT THE INFORMATION NOR THIS SITE IS FIT FOR A PARTICULAR PURPOSE;
                THAT DEFECTS WILL BE CORRECTED; THAT THIS SITE IS FREE FROM VIRUSES OR OTHER DESTRUCTIVE DEVICES OR PROCESSES;
                OR, THAT YOUR USE OF THE INFORMATION WILL NOT INFRINGE UPON THE RIGHTS OF OTHERS.GSC AND FIDELITY EXPRESS ASSUME NO LIABILITY OR
                RESPONSIBILITY FOR ANY MISTAKES, ERRORS OR OMISSIONS PROVIDED ON OR THROUGH THIS SITE AND IS NOT LIABLE FOR ANY OR ALL COST OF REPAIRS,
                SERVICE OR OTHER CORRECTION IN THE UNLIKELY EVENT OF DAMAGE ARISING FROM OR THROUGH YOUR USEE OF THIS SITE OR ANY OF ITS INFORMATION OR CONTENT.
            </Typography>
            <Typography align="center" variant="h6">TERMS OF USE</Typography>
            <Typography align="left" variant="body1" gutterBottom={true}>
                All use and access to this Site is governed by and is subject to the terms and conditions set forth herein.
                You should read these terms carefully and adhere to all terms.
            </Typography>
            <Typography align="left" variant="body1" gutterBottom={true}>
                BY ACCESSING AND USING THIS SITE, YOU ACKNOWLEDGE AND AGREE THAT YOU HAVE READ,
                UNDERSTAND, ACCEPT AND ARE BOUND BY THE TERMS OF USE.IF YOU DO NOT AGREE TO THESE TERMS,
                YOU SHOULD NOT CONTINUE TO ACCESS OR USE THIS SITE.THE TERMS OF USE ARE SUBJECT TO CHANGE.
                YOUR USE OF THIS SITE AFTER CHANGES HAVE BEEN IMPLEMENTED CONSITUTES YOUR ACCEPTANCE OF THE CHANGES.
                YOU SHOULD CONSULT THE TERMS OF USE REGULARLY. THIS SITE MAY BE MONITORED TO SECURE COMPLIANCE WITH THE TERMS OF USE.
            </Typography>
            <Typography align="center" variant="h6">ACCESS TO SITE</Typography>
            <Typography align="left" variant="body1" gutterBottom={true}>
                Certain registration and other information may be required to access this Site and some of its resources.
                You agree that a condition of your use of this Site requires providing true, current, correct and complete information.
                GSC and Fidelity Express reserve the right to modify or terminate the Terms of Use, and to refuse, restrict,
                deny or discontinue service or access to this Site to any person or entity, for any reason, or for no reason whatsoever,
                at any time, without any notice or liability.
                Failure to comply with the Terms of Use may result in the immediate termination of your access and use of this Site.
            </Typography>
            <Typography align="center" variant="h6">THE SERVICES</Typography>
            <Typography align="left" variant="body1" gutterBottom={true}>
                The Website may provide service tools such as bill pay searches, store locators,
                money order verification, commissions, sales and/or other service ("the Services") designed to enable you to use for business purposes only.
                Unless stated otherwise the Services are for business use only.
            </Typography>
            <Typography align="center" variant="h6">USE OF SUBMITTED INFORMATION</Typography>
            <Typography align="left" variant="body1" gutterBottom={true}>
                You may be required to submit information to this Site in order to access and use this Site.
                By submitting such information, you hereby expressly grant GSC Enterprises, Inc. and its Division,
                Fidelity Express, a royalty-free, worldwide, irrevocable, perpetual,
                non-exclusive fully-paid right and license to use, modify, amend, reproduce, adapt, publish,
                translate, and distribute such information, in whole or in part,
                and to incorporate it into any other works in any form without maintaining the information as confidential
                except as provided by our Privacy Policy set forth on this Site.
                GSC and Fidelity Express encourage you to read and understand our Privacy Policy and practices
                as further explained in our Privacy Policy as set forth below.
            </Typography>
            <Typography align="center" variant="h6">PRIVACY POLICY</Typography>
            <Typography align="left" variant="body1" gutterBottom={true}>
                We are committed to responsible data management and subscribe to the principals of the data
                protection legislation in the United States. We are committed to maintaining the privacy of our users and maintaining the security of any personal information received from you.If you register for any of the Services you will be asked to provide basic personal information.The information provided by you is not available for sale or use by third parties.The information is used solely for notifying you of changes or updates to the Website/Services.
            </Typography>
            <Typography align="left" variant="body1" gutterBottom={true}>
                We use a technology called "cookies" as part of our normal business procedure to track logged
                in users of the Website. A cookie is an element of data that the Website sends to your browser
                that is then stored on your system. You can set your browser to prevent this happening; however,
                setting your browser to block cookies may result in the website becoming unusable.
            </Typography>
            <Typography align="center" variant="h6">SECURITY OF INFORMATION, USER ACCOUNT, AND PASSWORD</Typography>
            <Typography align="left" variant="body1" gutterBottom={true}>
                The agent/biller services requires you to obtain an account from Fidelity Express that
                you will be required to provide to view certain information for the Services.
                You are responsible for maintaining the confidentiality of the username and password
                and also for all activities which take place under your account.
                You are also responsible for notifying Fidelity Express if the account should be removed or changed.
                You also agree to immediately notify us of any unauthorised use of your password or account or any other breach of security.
                In no event will GSC ENTERPRISES, INC., ANY OF ITS DIVISIONS, OFFICERS, DIRECTORS, SHAREHOLDERS,
                CONTRACTORS, LICENSORS, PROVIDERS, SUPPLIERS, CONTENT PROVIDERS, SERVICE PROVIDERS, EMPLOYEES,
                AGENTS, REPRESENTATIVES, OR ANYONE SIMILARLY SITUATED be liable for any loss or damages,
                indirect or consequential, whatsoever resulting from the disclosure of your username and/or password.
                You may not use another person"s account at any time.
            </Typography>
            <Typography align="left" variant="body1" gutterBottom={true}>
                GSC and Fidelity Express, as it deems commercially appropriate,
                necessary and reasonable, take steps to secure and protect any and all incoming and outgoing information to and from this Site.
                GSC and Fidelity Express do not guarantee that these transmissions will be secure.
            </Typography>
            <Typography align="center" variant="h6">USE OF SITE AND CONTENT</Typography>
            <Typography align="left" variant="body1" gutterBottom={true}>
                Use and access to this Site shall be granted solely for legitimate business purposes.
                All other use is strictly prohibited.You and any one authorized by you to access or use this Site shall
                be responsible for compliance with all applicable laws and regulations relevant to your jurisdiction as well as the requirements of this Site.
            </Typography>
            <Typography align="left" variant="body1" gutterBottom={true}>
                Any alteration, modification, copying, transfer or other use of all or any part
                of this Site shall be a violation of the terms and shall be strictly prohibited
                without the prior written permission of GSC Enterprises, Inc. and/or Fidelity Express.
                The information provided in this Site is proprietary to GSC Enterprises, Inc.
                and you shall not change, modify, alter or cause to be removed any proprietary notice,
                trademark or copyright notation on this Site or on any of its contents.You acknowledge
                and agree that the information provided on this Site is valuable; proprietary in nature;
                and, that GSC owns this proprietary information.
                It is further agreed that you obtain no ownership interest in this proprietary information by
                your use of this Site or any of its contents. You acknowledge that all information, text,
                graphics, logos, photographs, images, sound, illustrations and other materials ("the Content"),
                whether publicly or transmitted privately, are the sole responsibility of the person from such Content originated.
            </Typography>
            <Typography align="center" variant="h6">PROHIBITED USE OF SITE</Typography>
            <Typography align="left" variant="body1" gutterBottom={true}>
                In using the Website/Services you agree not to:
            </Typography>
            <Typography align="left" variant="body1" gutterBottom={true}>
                Use any information or material in any manner that infringes any copyright, trademark, patent or other proprietary right of any party;
                Collect or store personal information about others, including but not biller information, and checking information;
                Impersonateany person or entity for the purpose of misleading others;
                Violate any applicable laws or regulations;
                Use the Website/Services in any manner that could damage, disable, overburden or impair the
                Website/Services or interfere with any other party"s use of the Website/Services; or,
                Attempt to gain unauthorised access to any of the Services, other accounts, computer systems
                or networks connected to the Website/Services through hacking, social engineering, password mining or any other means.
                You further agree that neither you nor any one that you authorize will:
            </Typography>
            <Typography align="left" variant="body1" gutterBottom={true}>
                Post any material on this Site.
                Restrict or prevent any other user from using or accessing the Site.
                Alter, tamper, modify or change the content on this Site.
                Support or otherwise advocate illegal actions or conduct that would lead to criminal action,
                including discussion of any intent to commit an illegal act.
                Support or otherwise advocate conduct that could give rise to civil liability or that could violate any law.
                Use, transmit or post any vulgar, pornographic, indecent or obscene language, text, signs, symbols
                or any other means of communications, or threatens, abuses or harasses others, slanders, libels,
                defames, invades the privacy or in any way infringes upon the rights of others.
                Seek to exploit children by exposing them to any inappropriate content or requesting any personal information.
                Falsely identify yourself or the party that you represent.
                Post, transmit or otherwise submit any solicitations, advertisements, chain letters, investment schemes
                or "opportunities", pyramid schemes, unsolicited communications, spamming, flooding
                or any other action that hampers use or service of this Site.
                Post, transmit or otherwise submit any information or software containing a virus, worm,
                Trojan horse, or any other code, file or program that interferes, disables, effects, destroys, harms or limits the functionality of any computer.
                Infringes upon any intellectual or proprietary information or rights.
                Post, transmit or otherwise submit any information which contains links to others that are in violation to any provision above.
                You acknowledge and agree that GSC Enterprises, Inc., and all its divisions, assume no liability for the use, contents or access to this Site.
            </Typography>
            <Typography align="center" variant="h6">ACCOUNT TERMINATION</Typography>
            <Typography align="left" variant="body1" gutterBottom={true}>
                We have the right to terminate your access to any or all of the Services at any time,
                without notice, for any reason, including without limitation, breach of these Terms and Conditions.
                We may also at any time, at our sole discretion, discontinue the Website/Services or any part
                thereof without prior notice and you agree that we shall not be liable to you or any third party
                for any termination of your access to the Website/Services.
            </Typography>
            <Typography align="center" variant="h6">LINKS TO SITE</Typography>
            <Typography align="left" variant="body1" gutterBottom={true}>
                You acknowledge and agree that any links to this Site must first be approved in writing from GSC Enterprises,
                Inc. and/or Fidelity Express. You further agree and understand that any links from this Site are not under the control,
                and are not endorsed by GSC Enterprises, Inc. or Fidelity Express. You also agree that GSC Enterprises, Inc.
                and Fidelity Express are not responsible for the accuracy, completeness or availability of any other Site, or its products or services.
            </Typography>
            <Typography align="center" variant="h6">LIMITATION OF LIABILITY</Typography>
            <Typography align="left" variant="body1" gutterBottom={true}>
                IN NO EVENT SHALL, GSC ENTERPRISES, INC., ANY OF ITS DIVISIONS, OFFICERS, DIRECTORS, SHAREHOLDERS,
                CONTRACTORS, LICENSORS, PROVIDERS, SUPPLIERS, CONTENT PROVIDERS, SERVICE PROVIDERS, EMPLOYEES, AGENTS,
                REPRESENTATIVES, OR ANYONE SIMILARLY SITUATED BE LIABLE TO YOU, YOUR COMPANY OR ANY THIRD PARTY FOR ANY LOSS,
                COST, DAMAGE OR OTHER INJURY THAT IS INCIDENTAL, DIRECT,CONSEQUENTIAL, INDIRECT, PUNITIVE, EXEMPLARY,
                SPECIAL OR OTHERWISE, WHETHER KNOWN OR UNFORESEEN, REGARDLESS OF THE CAUSE OR FORM OF ACTION,
                INCLUDING BUT NOT LIMITED TO, LOSS OF REVENUE OR INCOME, LOST OPPORTUNITY COSTS, PAIN AND SUFFERING,
                EMOTIONAL DISTRESS OR SIMILAR DAMAGES, LOSS OF GOODWILL, LOSS OR DAMAGE TO PROPERTY, LOSS OF USE,
                REGARDLESS OF WHETHER GSC KNEW OR SHOULD HAVEKNOWN OF SUCH POSSIBLE DAMAGES OR COSTS.IF GSC ENTERPRISES,
                INC., ANY OF ITS DIVISIONS, OFFICERS, DIRECTORS, SHAREHOLDERS, CONTRACTORS, LICENSORS, PROVIDERS,
                SUPPLIERS, CONTENT PROVIDERS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, REPRESENTATIVES,
                OR ANYONE SIMILARLY SITUATED IS FOUND LIABLE AS A RESULT OF ANY CLAIM OR OTHER MATTER ARISING FROM,
                THROUGH OR AS A RESULT OF ACCESS OR USE OF THIS SITE OR ANY OF ITS INFORMATION OR CONTENT, THEN THE CUMULATIVE,
                AGGREGATEAND MAXIMUM LIABLILITY SHALL NOT EXCEED $250.00.
            </Typography>
            <Typography align="center" variant="h6">INDEMNIFICATION</Typography>
            <Typography align="left" variant="body1" gutterBottom={true}>
                You agree to defend, indemnify and hold harmless GSC ENTERPRISES, INC., ALL OF ITS DIVISIONS, OFFICERS,
                DIRECTORS, SHAREHOLDERS, CONTRACTORS, LICENSORS, PROVIDERS, SUPPLIERS, CONTENT PROVIDERS, SERVICE PROVIDERS,
                EMPLOYEES, AGENTS, REPRESENTATIVES, OR ANYONE SIMILARLY SITUATED, (the "indemnified parties") from all claims,
                demands, suits or other judicial or administrative proceedings and from all resulting loss, damage, liability,
                cost, fees, or expenses, including attorney"s fees, arising from or out of your access or use of this Site or
                any information or content from this Site, your violation of the terms of use or violation of any rights of others.
                GSC reserves and you grant the right to GSC to select its counsel and to defend and control any matter subject to indemnification by you.
            </Typography>
            <Typography align="center" variant="h6">JURISDICTION AND VENUE</Typography>
            <Typography align="left" variant="body1" gutterBottom={true}>
                The Terms of Use and all other aspects of this Site shall be governed by and interpreted pursuant to the laws of the State of Texas,
                without regard to conflicts of law principles or rules.By accessing or using this Site or any information or contents from this Site,
                you consent to personal jurisdiction in Texas relating to any dispute between you and GSC and/or Fidelity Express.
                You further agree that the exclusive venue for any dispute between you and GSC or Fidelity Express shall be
                in Hopkins County to which you hereby consent and waive any claim that venue is improper for any reason.
            </Typography>
            <Typography align="center" variant="h6">ENTIRETY OF CONTRACT</Typography>
            <Typography align="left" variant="body1" gutterBottom={true}>
                These Terms of Use and any privacy policy relating to this Site along with any and all amendments,
                modifications or changes constitute the entire agreement with respect to your access and use of the Site,
                its contents, its information, or the subject matter thereof.GSC and Fidelity Express reserve
                the right to revise the terms and/or this Site at any time for any reason, or for no reason at all.
                The accessing or using of this Site will not supersede any separate written agreement between you and GSC or Fidelity Express,
                but shall be deemed in addition to any such other agreement between you and GSC or Fidelity Express.
                This agreement shall be binding upon and inure to the benefit of you and GSC and their respective heirs, successors and assigns.
                If any provision is held to be unlawful, illegal, void, invalid, or unenforceable by a court of competent jurisdiction,
                then that provision shall be deemed severable and it will not affect the validity or enforceability of any other provision.
            </Typography>
            <Typography align="center" variant="h6">Last updated: September 9, 2008</Typography>
            <Typography align="center" variant="h6">Copyright 2008 GSC Enterprises, Inc. All rights reserved.</Typography>
        </Box>
    );
}