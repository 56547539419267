import { useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';
import { AuthContext, FullAuth } from '../../HigherOrder/AuthController/AuthController';

interface Props {
    open: boolean,
    setOpen: (open: boolean) => void
}

export default function AMLDialog({ open, setOpen }: Props) {

    const { push } = useHistory();
    const { userData } = useContext(AuthContext) as FullAuth;

    const handleClose = () =>
        setOpen(false);

    const takeAML = () => {
        push('/AML');
        handleClose();
    }

    const openPDF = () => {
        const pdf = userData?.MOProducts > 0 ? 'amlTrainingGuide.pdf' : 'amlTrainingGuideBPOnly.pdf';
        window.open('/pdf/' + pdf);
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>
                FinCen AML Compliance
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    As an agent of Fidelity Express and as a seller of money orders which is a Money Service Business (MSB),
                    you are required by State and Federal law to train at least once a year.
                    Our records indicate that you are currently out of anti-money laundering (AML) compliance.
                    To be within compliance you must complete and pass the AML Examination.
                    Please click on the "AML Training PDF" and after review click on "AML Exam".
                    Your score will be automatically tabulated, a score of 70% or better is required.
                    If you do not pass you will be prompted to review the AML Training Guide PDF again and allowed to retake the test.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Remind me later
                </Button>
                <Button onClick={openPDF} color="primary">
                    AML Training PDF
                </Button>
                <Button onClick={takeAML} color="primary">
                    AML Exam
                </Button>
            </DialogActions>
        </Dialog>
    );
}