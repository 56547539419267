import { useContext, Fragment } from 'react';
import { Dialog, DialogTitle, DialogContent, Grid, Button, CircularProgress } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { AuthContext, FullAuth } from '../../HigherOrder/AuthController/AuthController';
import { useSnackbar } from 'notistack';

const initialValues = {
    feedback: ''
}

interface Props {
    onClose: () => void
}

export default function Feedback({ onClose }: Props) {

    const { enqueueSnackbar } = useSnackbar();
    const { userData: { auth }, handleErrors } = useContext(AuthContext) as FullAuth;

    const handleFeedbackRequest = async ({ feedback }: typeof initialValues) => {
        await fetch(`${process.env.REACT_APP_API_URL}/feedback`, {
            method: 'POST',
            body: new URLSearchParams({
                auth, feedback
            })
        }).then(handleErrors);
    };

    const handleSubmit = async (values: typeof initialValues) => {
        try {
            await handleFeedbackRequest(values);
            onClose();
            enqueueSnackbar('Thank you for your feedback!', { variant: 'success' });
        } catch (err) {
            console.error(err);
            if (err instanceof Error) {
                enqueueSnackbar(err.message, { variant: 'error' });
            } else {
                enqueueSnackbar('An unexpected error has occurred', { variant: 'error' });
            }
        }
    };

    return (
        <Dialog onClose={onClose} maxWidth='sm' fullWidth open>
            <DialogTitle color='primary'>
                Feedback
            </DialogTitle>
            <DialogContent dividers>
                <Formik initialValues={initialValues} onSubmit={handleSubmit} >
                    {({ isSubmitting }) =>
                        <Fragment>
                            {isSubmitting &&
                                <CircularProgress color="primary" style={{ left: 'calc(50% - 20px)', top: 'calc(50% - 20px)', position: 'absolute' }} />
                            }
                            <Form>
                                <Grid alignItems="center" container spacing={3}>
                                    <Grid item xs={12} >
                                        <Field
                                            component={TextField}
                                            variant="outlined"
                                            margin="normal"
                                            name="feedback"
                                            label='Please tell us your feedback!'
                                            required
                                            multiline
                                            fullWidth />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={isSubmitting}
                                            type='submit'
                                            fullWidth >
                                            Submit Feedback
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        </Fragment>
                    }
                </Formik>
            </DialogContent>
        </Dialog >
    )
}