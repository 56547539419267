import Navigator from './Navigator/Navigator';
import AuthController from '../HigherOrder/AuthController/AuthController';
import AnalyticsController from '../HigherOrder/AnalyticsController/AnalyticsController';
import ThemeController from '../HigherOrder/ThemeController/ThemeController';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { BrowserRouter } from "react-router-dom";
import DateFnsUtils from '@date-io/date-fns';
import './App.css';

export default function App() {

    return (
        <BrowserRouter>
            <ThemeController>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <AuthController >
                        <AnalyticsController />
                        <Navigator />
                    </AuthController>
                </MuiPickersUtilsProvider>
            </ThemeController>
        </BrowserRouter>
    );
}