import "core-js/stable";
import "regenerator-runtime/runtime";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import App from './App/App';

import './i18n';

ReactDOM.render(
    <>
        <CssBaseline />
        <App />
    </>
    ,
    document.querySelector('#root'),
);
