import { useState, createContext, ReactNode, useMemo, useCallback } from 'react';
import { parse, differenceInDays, differenceInYears } from 'date-fns';
import status from 'statuses';
import { implementedOptionMap } from '../../App/Navigator/Navigator';
import IdleTimer from 'react-idle-timer';

export interface Option {
    optionCode: string,
    optionDescription: string,
    optionText: string,
    optionURL: string
}

export interface Agent {
    id: number,
    memoId?: number,
    name: string,
    active: string
}

export interface BillerAgent {
    agent: number,
    name: string,
    active: string
}

export interface UserData {
    BPProducts: number,
    MOProducts: number,
    agent: number,
    agents: Agent[],
    billerAgents: BillerAgent[],
    auth: string,
    commonOwner: number,
    priceGroup: string,
    company: string,
    companyName: string,
    email: string,
    firstName: string,
    lastName: string,
    options: Option[],
    passwordLastChanged: string,
    phone: string,
    timeoutInterval: number,
    userAgreement: string,
    username: string,
    lastAMLDate?: number
}

export const AuthContext = createContext({
    userData: null as UserData | null,
    optionMap: new Map<string, Option>(),
    login: (userData: UserData) => { },
    logout: () => { },
    needsProfileUpdate: false,
    needsUserAgreement: false,
    updateUserData: (userData: Partial<UserData>) => { },
    handleErrors: (response: Response) => {
        if (!response.ok) {
            throw new FetchError(response);
        }
        return response;
    }
});

export interface FullAuth {
    userData: UserData,
    optionMap: Map<string, Option>,
    login: (userData: UserData) => void,
    logout: () => void,
    needsProfileUpdate: boolean,
    needsUserAgreement: boolean,
    updateUserData: (userData: Partial<UserData>) => void,
    handleErrors: (response: Response) => Response
}

export class FetchError extends Error {
    status;

    constructor(response: Response) {
        if (response.statusText) {
            super(response.statusText);
        } else {
            super(`${response.status} - ${status(response.status)}`);
        }

        this.status = response.status;
    }
}

const lastUserAgreementUpdate = parse('01/01/01', 'MM/dd/yy', new Date());

const getSavedUserData = () => {
    const dataJSON = sessionStorage.getItem('userData');
    return dataJSON ? JSON.parse(dataJSON) as UserData : null;
}

interface Props {
    children: ReactNode
}

export default function AuthController({ children }: Props) {
    const [userData, setUserData] = useState(getSavedUserData());

    const optionMap = useMemo(() =>
        new Map<string, Option>(
            userData?.options?.filter(({ optionCode }) =>
                implementedOptionMap.has(optionCode)
            ).map((option) =>
                [option.optionCode, option]
            ) ?? [])
        , [userData?.options]);

    const login = useCallback((userData: UserData) => {
        setUserData(userData);
        sessionStorage.setItem('userData', JSON.stringify(userData));
    }, [])

    const logout = useCallback(() => {
        setUserData(null);
        sessionStorage.removeItem('userData');
    }, [])

    const needsUserAgreement = useMemo(() => {
        const userAgreementDate = parse(userData?.userAgreement ?? '', 'MM/dd/yy', new Date());
        return !userData?.userAgreement || differenceInDays(lastUserAgreementUpdate, userAgreementDate) > 0;
    }, [userData?.userAgreement])

    const needsProfileUpdate = useMemo(() => {
        const passwordChangedDate = parse(userData?.passwordLastChanged ?? '', 'yyyy-MM-dd HH:mm:ss.SSSSSS', new Date());
        return differenceInYears(new Date(), passwordChangedDate) > 0;
    }, [userData?.passwordLastChanged])

    const updateUserData = useCallback((newUserData: Partial<UserData>) => {
        setUserData((currentUserData) => {
            if (currentUserData) {
                return {
                    ...currentUserData,
                    ...newUserData
                };
            }
            return currentUserData;
        });
    }, []);

    const handleErrors = useCallback((response: Response) => {
        if (response.status === 403 || response.status === 401) {
            logout();
        }
        if (!response.ok) {
            throw new FetchError(response);
        }
        return response;
    }, [logout]);

    const timeout = (userData?.timeoutInterval ?? 30) * 1000 * 60;

    return (
        <AuthContext.Provider value={{
            userData,
            optionMap,
            login,
            logout,
            needsProfileUpdate,
            needsUserAgreement,
            updateUserData,
            handleErrors
        }}>
            <IdleTimer
                onIdle={logout}
                timeout={timeout} />
            {children}
        </AuthContext.Provider>
    );
}