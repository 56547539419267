
import { useContext } from 'react';
import { IconButton, Typography, Toolbar, AppBar, Box, Hidden, Paper, Tooltip } from '@material-ui/core';
import { ExitToAppOutlined, Menu } from '@material-ui/icons'
import { AuthContext, FullAuth } from '../../HigherOrder/AuthController/AuthController';
import { useLocation } from "react-router-dom";
import PHNLogo from '../../images/phnLogo.png';
import FELogo from '../../images/feLogo.png';
import GSCLogo from '../../images/gscLogo.png';
import GSCIcon from '../../images/gscIcon.png';
import { ThemeContext } from '../../HigherOrder/ThemeController/ThemeController';
import './AppHeader.css';

const devStamp = process.env.REACT_APP_SERVER !== 'PROD' ? ' *DEV*  ' : '';

interface Props {
    setDrawerOpen: (open: boolean) => void,
    open: boolean
}

const titles = {
    'OptIn': 'Update Marketing Preferences',
    'O': 'Update Marketing Preferences'
} as Record<string, string>;

export default function AppHeader({ setDrawerOpen, open }: Props) {

    const { pathname } = useLocation();
    const { themeType, isGSCPage } = useContext(ThemeContext);
    const { userData, optionMap, logout, needsUserAgreement, needsProfileUpdate } = useContext(AuthContext) as FullAuth;

    const handleDrawerOpen = () =>
        setDrawerOpen(true);

    const firstSegment = pathname.split('/')[1];

    const title = devStamp + (optionMap.get(firstSegment)?.optionText ?? titles[firstSegment] ?? firstSegment);

    const drawerCanOpen = !!(userData?.options) && !needsProfileUpdate && !needsUserAgreement;
    const drawerIsOpen = open && drawerCanOpen;

    return (
        <AppBar position="absolute" color='primary' className={`AppBar ${drawerIsOpen ? 'Shift' : ''}`}>
            <Toolbar className={'ToolBar'}>
                <Box display='flex' alignItems='center' width='400px' flexGrow='1'>
                    {!open && drawerCanOpen &&
                        <IconButton
                            edge="start"
                            onClick={handleDrawerOpen}
                            className={'MenuButton'}
                            color="inherit"
                        >
                            <Menu />
                        </IconButton>
                    }
                    <div>
                        <Typography variant="h6" noWrap className={'Title'}>
                            {title}
                        </Typography>
                        <Typography variant="subtitle1" noWrap>
                            Help? 800-621-8030
                        </Typography>
                    </div>

                </Box>
                <Box display='flex' alignItems='center' flexGrow='2' >
                    <Hidden smDown={open} xsDown={!open}>
                        {isGSCPage &&
                            <Box component={Paper} bgcolor='white' display='flex' alignItems='center' >
                                <img className='HeaderLogo' src={GSCIcon} alt='GSC' />
                                <img className='HeaderLogo' src={GSCLogo} alt='GSC' />
                            </Box>
                        }
                        {!isGSCPage && themeType === 'dark' &&
                            <a href="https://fidelityexpress.co/" ><img src={PHNLogo} alt='PayHereNetwork' /></a>
                        }
                        {!isGSCPage && themeType !== 'dark' &&
                            <Box component={Paper} bgcolor='white' display='flex' alignItems='center' >
                                <a href="https://fidelityexpress.co/" ><img src={FELogo} alt='Fidelity Express' /></a>
                            </Box>
                        }
                    </Hidden>
                </Box>
                {userData &&
                    <Tooltip title='Log Out'>
                        <IconButton color="inherit" onClick={logout}>
                            <ExitToAppOutlined />
                        </IconButton>
                    </Tooltip>
                }
            </Toolbar>
        </AppBar>
    )
}