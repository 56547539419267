import { useEffect, useContext, useCallback, useRef, useMemo } from 'react';
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga';
import { AuthContext, FullAuth } from '../AuthController/AuthController';
import { differenceInMilliseconds } from 'date-fns';

interface PageTime {
    pageId: string,
    viewTime: number
}

const sendPageTimes = (auth: string, pageTimes: PageTime[]) => {
    const blob = new Blob([new URLSearchParams({
        auth, pageTimes: JSON.stringify(pageTimes)
    }).toString()], { type: 'application/x-www-form-urlencoded; charset=UTF-8' });
    navigator.sendBeacon(`${process.env.REACT_APP_API_URL}/monitoring/analytics`, blob);
};

export default function AnalyticsController() {

    const { pathname } = useLocation();
    const firstSegment = useMemo(() =>
        pathname.split('/')[1]
        , [pathname]);

    const { userData } = useContext(AuthContext) as FullAuth;
    const username = userData?.username;
    const auth = userData?.auth;

    const prevSegment = useRef(firstSegment);
    const prevAuth = useRef(auth);
    const pageTimeMap = useRef(new Map<string, PageTime>());
    const pageEnterTime = useRef(new Date());
    const initialized = useRef(false);

    const addPageTime = useCallback((pageId: string, viewTime: number) => {
        if (pageTimeMap.current.has(pageId)) {
            const pageTime = pageTimeMap.current.get(pageId)!;
            pageTime.viewTime += viewTime;
        } else {
            pageTimeMap.current.set(pageId, { pageId, viewTime });
        }
    }, []);

    const pushCurrentPage = useCallback(() => {
        addPageTime(prevSegment.current, differenceInMilliseconds(new Date(), pageEnterTime.current));
        prevSegment.current = firstSegment;
        pageEnterTime.current = new Date();
    }, [addPageTime, firstSegment]);

    const handleVisibilityChange = useCallback(() => {
        if (document.visibilityState === 'hidden') {
            pushCurrentPage();
            if (auth) {
                sendPageTimes(auth, Array.from(pageTimeMap.current.values()));
            }
        } else if (document.visibilityState === 'visible') {
            pageTimeMap.current.clear();
            pageEnterTime.current = new Date();
        }
    }, [auth, pushCurrentPage]);

    // Mount / Unmount Logic for AS400 Analytics
    useEffect(() => {
        document.addEventListener("visibilitychange", handleVisibilityChange, false);
        return function cleanup() {
            document.removeEventListener("visibilitychange", handleVisibilityChange, false);
        }
    }, [handleVisibilityChange]);

    // Page change logic for AS400 Analytics
    useEffect(() => {
        if (prevSegment.current !== firstSegment) {
            pushCurrentPage();
        }
    }, [firstSegment, pushCurrentPage]);

    // User change logic for AS400 Analytics (Login / Logout)
    useEffect(() => {
        if (prevAuth.current !== auth) {
            if (prevAuth.current) {
                pushCurrentPage();
                sendPageTimes(prevAuth.current, Array.from(pageTimeMap.current.values()));
                pageTimeMap.current.clear();
            }
            prevAuth.current = auth;
        }
    }, [auth, pushCurrentPage]);

    // Startup / Username Change For Google Analytics
    useEffect(() => {
        if (!initialized.current) {
            ReactGA.initialize('UA-86789706-1', {
                gaOptions: {
                    siteSpeedSampleRate: 100,
                    userId: username
                }
            });
            initialized.current = true;
        } else {
            ReactGA.ga("set", "userId", username);
        }
    }, [username]);

    // Page view for Google Analytics
    useEffect(() => {
        if (initialized.current) {
            ReactGA.pageview(pathname);
        }
    }, [pathname]);

    return null;
}