import { useState, useEffect, createContext, ReactNode, useCallback, useRef } from 'react';
import { Helmet } from "react-helmet";
import { lightTheme, darkTheme, gscTheme, memoTheme, GSCBlue, PHNGreen, FERed, MEMOGreen } from '../../theme';
import { useLocation } from 'react-router-dom';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';

const savedTheme = localStorage.getItem('theme') === 'dark' ? 'dark' : 'light';

export const ThemeContext = createContext({
    switchThemeType: async (themeType: 'light' | 'dark') => { },
    themeType: 'light' as 'light' | 'dark',
    isGSCPage: false as boolean,
    isMEMOPage: false as boolean
});

interface Props {
    children: ReactNode
}

export default function ThemeController({ children }: Props) {

    const { pathname } = useLocation();

    const segments = pathname.split('/');

    const isGSCPage = segments[1] === 'BRENHAMACH';
    const isMEMOPage = segments[2] === 'MEMO';

    const themeSwitchResolver = useRef(() => { });

    const [preferredThemeType, setPreferredThemeType] = useState<'light' | 'dark'>(savedTheme);

    useEffect(() => {
        themeSwitchResolver.current();
    }, [preferredThemeType]);

    const switchThemeType = useCallback((themeType: 'light' | 'dark'): Promise<void> => {
        return new Promise((resolve) => {
            if (themeType === preferredThemeType) {
                resolve();
            } else {
                themeSwitchResolver.current = resolve;
                setPreferredThemeType(themeType);
                localStorage.setItem('theme', themeType);
            }
        });
    }, [preferredThemeType]);

    const themeType = (isGSCPage || isMEMOPage) ? 'light' : preferredThemeType;
    const theme = isGSCPage ? gscTheme
        : isMEMOPage ? memoTheme
            : themeType === 'dark' ? darkTheme
                : lightTheme;

    return (
        <>
            {isMEMOPage &&
                <Helmet>
                    <link rel="icon" type="image/png" sizes="512x512" href="/icons/FE/favicon-512.png" />
                    <link rel="icon" type="image/png" sizes="192x192" href="/icons/FE/favicon-192.png" />
                    <link rel="icon" type="image/png" sizes="32x32" href="/icons/FE/favicon-32.png" />
                    <link rel="icon" type="image/png" sizes="16x16" href="/icons/FE/favicon-16.png" />
                    <link rel="apple-touch-icon" type="image/png" sizes="180x180" href="/icons/FE/apple-touch-icon.png" />
                    <link rel="shortcut icon" href="/icons/FE/favicon.ico" />
                    <meta name="theme-color" content={MEMOGreen} />
                    <meta name="description" content="The Fidelity Express web portal." />
                    <title>Fidelity Express Web Portal</title>
                </Helmet>
            }
            {isGSCPage &&
                <Helmet>
                    <link rel="icon" type="image/png" sizes="192x192" href="/icons/GSC/favicon-192.png" />
                    <link rel="icon" type="image/png" sizes="32x32" href="/icons/GSC/favicon-32.png" />
                    <link rel="icon" type="image/png" sizes="16x16" href="/icons/GSC/favicon-16.png" />
                    <link rel="apple-touch-icon" type="image/png" sizes="180x180" href="/icons/GSC/apple-touch-icon.png" />
                    <link rel="shortcut icon" href="/icons/GSC/favicon.ico" />
                    <meta name="theme-color" content={GSCBlue} />
                    <meta name="description" content="The GSC web portal. A tool for GSC." />
                    <title>GSC Web Portal</title>
                </Helmet>
            }
            {!isGSCPage && themeType === 'dark' &&
                <Helmet>
                    <link rel="icon" type="image/png" sizes="512x512" href="/icons/PHN/favicon-512.png" />
                    <link rel="icon" type="image/png" sizes="192x192" href="/icons/PHN/favicon-192.png" />
                    <link rel="icon" type="image/png" sizes="32x32" href="/icons/PHN/favicon-32.png" />
                    <link rel="icon" type="image/png" sizes="16x16" href="/icons/PHN/favicon-16.png" />
                    <link rel="apple-touch-icon" type="image/png" sizes="180x180" href="/icons/PHN/apple-touch-icon.png" />
                    <link rel="shortcut icon" href="/icons/PHN/favicon.ico" />
                    <meta name="theme-color" content={PHNGreen} />
                    <meta name="description" content="The PayHereNetwork web portal. A tool for Fidelity Express Agents." />
                    <title>PayHereNetwork Web Portal</title>
                </Helmet>
            }
            {!isGSCPage && themeType !== 'dark' &&
                <Helmet>
                    <link rel="icon" type="image/png" sizes="512x512" href="/icons/FE/favicon-512.png" />
                    <link rel="icon" type="image/png" sizes="192x192" href="/icons/FE/favicon-192.png" />
                    <link rel="icon" type="image/png" sizes="32x32" href="/icons/FE/favicon-32.png" />
                    <link rel="icon" type="image/png" sizes="16x16" href="/icons/FE/favicon-16.png" />
                    <link rel="apple-touch-icon" type="image/png" sizes="180x180" href="/icons/FE/apple-touch-icon.png" />
                    <link rel="shortcut icon" href="/icons/FE/favicon.ico" />
                    <meta name="theme-color" content={FERed} />
                    <meta name="description" content="The Fidelity Express web portal. A tool for Fidelity Express Agents." />
                    <title>Fidelity Express Web Portal</title>
                </Helmet>
            }
            <StylesProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider maxSnack={2} anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}>
                        <ThemeContext.Provider value={{ switchThemeType, themeType, isGSCPage, isMEMOPage }}>
                            {children}
                        </ThemeContext.Provider>
                    </SnackbarProvider>
                </ThemeProvider>
            </StylesProvider>
        </>
    );
}