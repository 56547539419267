import { useContext, useState } from 'react';
import { ListItem, ListItemText, Drawer, Divider, IconButton, List, ListItemIcon, Box, useMediaQuery, Switch, FormControlLabel, Tooltip } from '@material-ui/core';
import { ChevronLeft, Dashboard, Feedback as FeedbackIcon, Send, Notifications as NotificationsIcon, NotificationsActive as NotificationsActiveIcon } from '@material-ui/icons';
import { AuthContext, FullAuth } from '../../HigherOrder/AuthController/AuthController';
import { useHistory, useLocation } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { ThemeContext } from '../../HigherOrder/ThemeController/ThemeController';
import Notifications from './Notifications';
import Feedback from './Feedback';
import { makeStyles } from '@material-ui/core/styles';
import { white, transparent } from '../../theme';
import { CompleteOptionCategory } from './Navigator';
import './OptionDrawer.css';

const useStyles = makeStyles(({ palette: { primary, secondary } }) => ({
    OptionDrawer: {
        "& .MuiListItem-root": {
            "&:hover": {
                backgroundColor: secondary.main,
                color: white,
                "& .MuiListItemIcon-root": {
                    backgroundColor: transparent,
                    color: white,
                },
            },
        },
        "& .Mui-selected": {
            backgroundColor: primary.main,
            color: white,
            "& .MuiListItemIcon-root": {
                backgroundColor: transparent,
                color: white,
            },
        },
    }
}));

interface Props {
    setDrawerOpen: (open: boolean) => void,
    open: boolean,
    optionCategories: CompleteOptionCategory[]
}

export default function OptionDrawer({ setDrawerOpen, open, optionCategories }: Props) {

    const [notificationsOpen, setNotificationsOpen] = useState(false);
    const [feedbackOpen, setFeedbackOpen] = useState(false);

    const { switchThemeType, themeType, isGSCPage } = useContext(ThemeContext);
    const { breakpoints } = useTheme();
    const matches = useMediaQuery(breakpoints.up('md'), { noSsr: true });

    const { push } = useHistory();
    const { pathname } = useLocation();
    const { needsUserAgreement, needsProfileUpdate } = useContext(AuthContext) as FullAuth;

    const classes = useStyles();

    const handleThemeSwitch = () => {
        if (themeType === 'dark') {
            switchThemeType('light');
        } else {
            switchThemeType('dark');
        }
    }

    const handleDrawerClose = () =>
        setDrawerOpen(false);

    const handleButtonClick = (optionCode: string) => {
        push(`/${optionCode}`);
        if (!matches) {
            handleDrawerClose();
        }
    }

    const handleNotificationsOpen = () =>
        setNotificationsOpen(true);

    const handleNotificationsClose = () =>
        setNotificationsOpen(false);

    const handleFeedbackOpen = () =>
        setFeedbackOpen(true);

    const handleFeedbackClose = () =>
        setFeedbackOpen(false);

    const onPage = (optionCode: string) =>
        pathname.startsWith('/' + optionCode + '/') || pathname === ('/' + optionCode);

    return (
        <>
            {!!optionCategories && !(needsUserAgreement || needsProfileUpdate) &&
                <Drawer
                    className={classes.OptionDrawer}
                    variant="permanent"
                    classes={{ paper: `DrawerPaper ${!open ? 'Close' : ''}` }}>
                    <Box display='flex' alignItems='center' justifyContent='flex-end' minHeight='64px' pr={1}>
                        <ListItem button onClick={handleNotificationsOpen} >
                            <ListItemIcon>
                                {notificationsOpen &&
                                    <NotificationsActiveIcon />
                                }
                                {!notificationsOpen &&
                                    <NotificationsIcon />
                                }
                            </ListItemIcon>
                            <ListItemText primary={'Notifications'} />
                        </ListItem>
                        {open &&
                            <IconButton onClick={handleDrawerClose} color='inherit'>
                                <ChevronLeft />
                            </IconButton>
                        }
                    </Box>
                    <Divider />
                    <List className='OptionList'>
                        {/* <ListItem button onClick={() => { handleButtonClick('Referral') }} selected={onPage('Referral')} >
                            <ListItemIcon>
                                <ContactMail />
                            </ListItemIcon>
                            <ListItemText primary={'Referral'} />
                        </ListItem> 
                    <Divider /> */}
                        <ListItem button onClick={handleFeedbackOpen} >
                            <ListItemIcon>
                                {feedbackOpen &&
                                    <Send />
                                }
                                {!feedbackOpen &&
                                    <FeedbackIcon />
                                }
                            </ListItemIcon>
                            <ListItemText primary={'Feedback'} />
                        </ListItem>
                        <Divider />
                        <ListItem button onClick={() => { handleButtonClick('Dashboard') }} selected={onPage('Dashboard')}>
                            <ListItemIcon>
                                <Dashboard />
                            </ListItemIcon>
                            <ListItemText primary={'Dashboard'} />
                        </ListItem>
                        <Divider />
                        {optionCategories.map(({ categoryOptions, CategoryIcon, categoryName }) =>
                            categoryOptions.map(({ optionCode, optionText, optionDescription }) =>
                                <Tooltip key={optionCode} enterDelay={200} enterNextDelay={200} title={optionDescription} placement='right' >
                                    <ListItem button onClick={() => { handleButtonClick(optionCode) }} selected={onPage(optionCode)}>
                                        <ListItemIcon>
                                            <CategoryIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={optionText} />
                                    </ListItem>
                                </Tooltip>
                            ).concat([<Divider key={`${categoryName}_Divider`} />])
                        )}
                    </List>
                    {!isGSCPage &&
                        <Box display='flex' alignItems='center' minHeight='64px' paddingX='8px'>
                            <Tooltip title='Switch Theme' enterDelay={200} enterNextDelay={200} placement='right' >
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={themeType === 'dark'}
                                            onChange={handleThemeSwitch}
                                            name="switchTheme"
                                            color="primary"
                                        />
                                    }
                                    label="Dark Theme"
                                />
                            </Tooltip>
                        </Box>
                    }
                    {notificationsOpen &&
                        <Notifications onClose={handleNotificationsClose} />
                    }
                    {feedbackOpen &&
                        <Feedback onClose={handleFeedbackClose} />
                    }
                </Drawer>
            }
        </>
    )
}