import { forwardRef, CSSProperties } from 'react';
import { Search, ViewColumn, SaveAlt, ChevronLeft, ChevronRight, FirstPage, LastPage, AddBox, Check, FilterList, Remove, ArrowDownward, Clear, DeleteOutline, Edit } from '@material-ui/icons';
import { format, parse, isValid } from 'date-fns';
import { Icons } from 'material-table';

export const parseTimestamp = (timestamp: string) => {
    const date = parse(timestamp, 'yyyy-MM-dd HH:mm:ss.SSSSSS', new Date());
    if (isValid(date)) {
        return date;
    }
    return parse(timestamp, 'yyyy-MM-dd-HH.mm.ss.SSSSSS', new Date());
}

export const parseDate = (date: string) =>
    parse(date, 'yyyy-MM-dd', new Date());

export const socketURL = '';

export const tableIcons: Icons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

export const getRowStyle = ({ summary }: { summary?: boolean }): CSSProperties =>
    summary ? { fontWeight: 'bold' } : {};

export const formatDate = (date: string) => {
    if (date) {
        const parsedDate = parse(date, 'yyyyMMdd', new Date());
        if (isValid(parsedDate)) {
            return format(parsedDate, 'MM/dd/yy');
        }
        return date;
    }
    return '';
}

export const formatTime = (time: string) => {
    if (time) {
        const parseTime = parse(time, 'Hmmss', new Date());
        if (isValid(parseTime)) {
            return format(parseTime, 'HH:mm:ss');
        }
        return time;
    }
    return '';
}