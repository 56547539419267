import { createTheme } from '@material-ui/core/styles';

const commonConfig = {
    overrides: {
        MuiTableCell: {
            root: {
                whiteSpace: 'nowrap' as const
            }
        }
    }
}

export const PHNGreen = '#a6d920';
export const black = '#000000';
export const white = '#FFFFFF';

export const FEBlue = '#00205C';
export const FERed = '#E1251B';
export const transparent = 'rgba(0,0,0,0.0)';

export const GSCBlue = '#002856';
const GSCGrey = '#B0B3B2';

export const MEMOGreen = '#007555';
const MEMOYellow = '#ffc400';

export const lightTheme = createTheme({
    palette: {
        primary: {
            main: FERed,
        },
        secondary: {
            main: FEBlue,
        },
        type: 'light' as const
    },
    overrides: {
        MuiAvatar: {
            colorDefault: {
                color: white,
                backgroundColor: FEBlue
            }
        },
        MuiDialogTitle: {
            root: {
                backgroundColor: FERed,
                color: white
            }
        },
        ...(commonConfig.overrides)
    }
});

export const darkTheme = createTheme({
    palette: {
        primary: {
            main: PHNGreen,
        },
        secondary: {
            main: black,
        },
        type: 'dark' as const
    },
    overrides: {
        MuiAvatar: {
            colorDefault: {
                color: black,
                backgroundColor: PHNGreen
            }
        },
        MuiDialogTitle: {
            root: {
                backgroundColor: PHNGreen,
                color: white
            }
        },
        ...(commonConfig.overrides)
    }
});

export const gscTheme = createTheme({
    palette: {
        primary: {
            main: GSCBlue,
        },
        secondary: {
            main: GSCGrey,
        },
        type: 'light' as const
    },
    overrides: {
        MuiAvatar: {
            colorDefault: {
                color: white,
                backgroundColor: GSCBlue
            }
        },
        MuiDialogTitle: {
            root: {
                backgroundColor: GSCBlue,
                color: white
            }
        },
        ...(commonConfig.overrides)
    }
});

export const memoTheme = createTheme({
    palette: {
        primary: {
            main: MEMOGreen,
        },
        secondary: {
            main: MEMOYellow,
        },
        type: 'light' as const
    },
    overrides: {
        MuiAvatar: {
            colorDefault: {
                color: white,
                backgroundColor: MEMOGreen
            }
        },
        MuiDialogTitle: {
            root: {
                backgroundColor: MEMOGreen,
                color: white
            }
        },
        ...(commonConfig.overrides)
    }
});