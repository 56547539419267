import { useState, useContext, useEffect, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, Box, CircularProgress } from '@material-ui/core';
import { AuthContext, FullAuth } from '../../HigherOrder/AuthController/AuthController';
import { useSnackbar } from 'notistack';
import NotificationList from './NotificationList';
import NotificationDetail from './NotificationDetail';

export interface Preview {
    id: number,
    preview: string,
    sentTime: string
}

export interface Detail extends Preview {
    notification: string
}

interface Props {
    onClose: () => void
}

export default function Notifications({ onClose }: Props) {

    const { enqueueSnackbar } = useSnackbar();
    const { userData: { auth }, handleErrors } = useContext(AuthContext) as FullAuth;

    const [previews, setPreviews] = useState([] as Preview[]);
    const [detail, setDetail] = useState(null as null | Detail);
    const [loading, setLoading] = useState(false);

    const handlePreviewsRequest = useCallback(async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/notifications/?` + new URLSearchParams({
            auth
        })).then(handleErrors);

        return await response.json() as Preview[];
    }, [auth, handleErrors]);

    const getPreviews = useCallback(async () => {
        setLoading(true);
        try {
            const previews = await handlePreviewsRequest();
            setPreviews(previews);
        } catch (err) {
            console.error(err);
            if (err instanceof Error) {
                enqueueSnackbar(err.message, { variant: 'error' });
            } else {
                enqueueSnackbar('An unexpected error has occurred', { variant: 'error' });
            }
            onClose();
        }
        setLoading(false);
    }, [enqueueSnackbar, handlePreviewsRequest, onClose]);

    useEffect(() => {
        getPreviews();
    }, [getPreviews]);

    const handleDetailRequest = async (id: number) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/notifications/?` + new URLSearchParams({
            auth,
            notificationId: id.toString()
        })).then(handleErrors);

        return await response.json() as { notification: string };
    };

    const getDetail = async (preview: Preview) => {
        setLoading(true);
        try {
            const { notification } = await handleDetailRequest(preview.id);
            setDetail({
                ...preview,
                notification
            });
        } catch (err) {
            console.error(err);
            if (err instanceof Error) {
                enqueueSnackbar(err.message, { variant: 'error' });
            } else {
                enqueueSnackbar('An unexpected error has occurred', { variant: 'error' });
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog onClose={onClose} maxWidth='xs' fullWidth open>
            <DialogTitle color='primary'>
                Notifications
            </DialogTitle>
            <DialogContent dividers>
                {loading &&
                    <Box height='100%' width='100%' display='flex' justifyContent='center'>
                        <CircularProgress color="primary" />
                    </Box>
                }
                {!loading && !!detail &&
                    <NotificationDetail previews={previews} detail={detail} getDetail={getDetail} setDetail={setDetail} />
                }
                {!loading && !detail &&
                    <NotificationList previews={previews} getDetail={getDetail} />
                }
            </DialogContent>
        </Dialog >
    )
}