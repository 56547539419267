import { useContext, Suspense, useState, lazy, useMemo, LazyExoticComponent, useCallback } from 'react';
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { AuthContext, Option } from '../../HigherOrder/AuthController/AuthController';
import { MoneyOff, BuildOutlined, AssignmentLateOutlined, AccountBalanceOutlined, AccountBalanceWalletOutlined, MonetizationOnOutlined, SearchOutlined, AllInclusive, ShowChart, Web } from '@material-ui/icons';
import OptionDrawer from './OptionDrawer';
import AppHeader from './AppHeader';
import Loading from './Loading';
import { Box, useMediaQuery, Hidden, SvgIconTypeMap } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AMLDialog from './AMLDialog';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import ContactDialog from './ContactDialog';
import TermsOfUse from '../Maintenance/Agreement/TermsOfUse';

export interface CompleteOption extends Option {
    OptionComponent: LazyExoticComponent<() => JSX.Element>
}

export interface CompleteOptionCategory {
    CategoryIcon: OverridableComponent<SvgIconTypeMap<{}, "svg">>,
    categoryName: string,
    categoryOptions: CompleteOption[];
}

const OptIn = lazy(() => import('../OptIn/OptIn'));
const Referral = lazy(() => import('../Referral/Referral'));
const Dashboard = lazy(() => import('../Dashboard/Dashboard'));
const Login = lazy(() => import('../Login/Login'));

export const optionCategories = [{
    CategoryIcon: AssignmentLateOutlined,
    categoryName: 'Anti Money Laundering',
    categoryOptions: [{
        optionCode: 'AMLADMIN',
        OptionComponent: lazy(() => import('../AML/AMLAdmin/AMLAdmin'))
    }, {
        optionCode: 'AML',
        OptionComponent: lazy(() => import('../AML/AMLTest/AMLTest'))
    }, {
        optionCode: 'AMLPOLICY',
        OptionComponent: lazy(() => import('../AML/AMLPolicyGuide/AMLPolicyGuide'))
    }, {
        optionCode: 'AMLPAST',
        OptionComponent: lazy(() => import('../AML/AMLPast/AMLPast'))
    }, {
        optionCode: 'AMLEMAIL',
        OptionComponent: lazy(() => import('../AML/AMLEmail/AMLEmail'))
    }, {
        optionCode: 'AGTCNTREV',
        OptionComponent: lazy(() => import('../AML/AgentContactReview/AgentContactReview'))
    }]
}, {
    CategoryIcon: SearchOutlined,
    categoryName: 'Troubleshooting Tools',
    categoryOptions: [{
        optionCode: 'TRANPATHR',
        OptionComponent: lazy(() => import('../Troubleshoot/Tranpath/Tranpath'))
    }, {
        optionCode: 'XMLTEST',
        OptionComponent: lazy(() => import('../Troubleshoot/XMLTEST/XMLTEST'))
    }]
}, {
    CategoryIcon: AccountBalanceOutlined,
    categoryName: 'Biller Tools',
    categoryOptions: [{
        optionCode: 'COBPS1',
        OptionComponent: lazy(() => import('../Biller/BillPay/BillPay'))
    }, {
        optionCode: 'COBPS3',
        OptionComponent: lazy(() => import('../Biller/BillPay/BillPay'))
    }, {
        optionCode: 'BLRAGT',
        OptionComponent: lazy(() => import('../Biller/AgentsAssignedToBillers/AgentsAssignedToBillers'))
    }, {
        optionCode: 'RPTHST',
        OptionComponent: lazy(() => import('../Biller/ReportHistory/ReportHistory'))
    }, {
        optionCode: 'BILCNT',
        OptionComponent: lazy(() => import('../Biller/BillerContacts/BillerContacts'))
    }, {
        optionCode: 'BILLERACH',
        OptionComponent: lazy(() => import('../Biller/BillerACH/BillerACH'))
    }]
}, {
    CategoryIcon: AccountBalanceWalletOutlined,
    categoryName: 'Agent Tools',
    categoryOptions: [{
        optionCode: 'AGTBLRFEE',
        OptionComponent: lazy(() => import('../Agent/AgentBillerFees/AgentBillerFees'))
    }, {
        optionCode: 'AGTBP',
        OptionComponent: lazy(() => import('../Agent/AgentBillPay/AgentBillPay'))
    }, {
        optionCode: 'AGTCNT',
        OptionComponent: lazy(() => import('../Agent/AgentContact/AgentContact'))
    }, {
        optionCode: 'MOSTAT',
        OptionComponent: lazy(() => import('../Agent/MoneyOrderStatus/MoneyOrderStatus'))
    }, {
        optionCode: 'ACHHSTM',
        OptionComponent: lazy(() => import('../Agent/ACHInquiry/ACHInquiry'))
    }, {
        optionCode: 'BPCOMM',
        OptionComponent: lazy(() => import('../Agent/BillPayCommissions/BillPayCommissions'))
    }, {
        optionCode: 'AGTCALSALB',
        OptionComponent: lazy(() => import('../Agent/YTDSalesBP/YTDSalesBP'))
    }, {
        optionCode: 'AGTCALSALM',
        OptionComponent: lazy(() => import('../Agent/YTDSalesMO/YTDSalesMO'))
    }, {
        optionCode: 'UPDBLRLST',
        OptionComponent: lazy(() => import('../Agent/BillerList/BillerList'))
    }, {
        optionCode: 'AGTRTMO',
        OptionComponent: lazy(() => import('../Agent/MoneyOrderSales/MoneyOrderSales'))
    }, {
        optionCode: 'MOINV',
        OptionComponent: lazy(() => import('../Agent/MoneyOrderInventory/MoneyOrderInventory'))
    }, {
        optionCode: 'MOOPN',
        OptionComponent: lazy(() => import('../Agent/MoneyOrderOutstanding/MoneyOrderOutstanding'))
    }, {
        optionCode: 'MOINQ',
        OptionComponent: lazy(() => import('../Agent/MoneyOrderInquiry/MoneyOrderInquiry'))
    }, {
        optionCode: 'AMTALRT',
        OptionComponent: lazy(() => import('../Agent/Notifications/Notifications'))
    }]
}, {
    CategoryIcon: MonetizationOnOutlined,
    categoryName: 'PayHereNetwork Tools',
    categoryOptions: [{
        optionCode: 'PHNMESSAGE',
        OptionComponent: lazy(() => import('../PayHereNetwork/PHNNotification/PHNNotification'))
    }, {
        optionCode: 'PHNPROMO',
        OptionComponent: lazy(() => import('../PayHereNetwork/PHNPromotion/PHNPromotion'))
    }, {
        optionCode: 'PHNPROBKT',
        OptionComponent: lazy(() => import('../PayHereNetwork/PHNPromotionBuckets/PHNPromotionBuckets'))
    }, {
        optionCode: 'PHNSRVINFO',
        OptionComponent: lazy(() => import('../PayHereNetwork/PHNServerFailover/PHNServerFailover'))
    }, {
        optionCode: 'PHNSUPPORT',
        OptionComponent: lazy(() => import('../PayHereNetwork/PHNSupport/PHNSupport'))
    }, {
        optionCode: 'PHNUSRADMN',
        OptionComponent: lazy(() => import('../PayHereNetwork/PHNUserMaintenance/PHNUserMaintenance'))
    }, {
        optionCode: 'PHNUSRSTAT',
        OptionComponent: lazy(() => import('../PayHereNetwork/PHNUserStatus/PHNUserStatus'))
    }, {
        optionCode: 'PHNSURVEY',
        OptionComponent: lazy(() => import('../PayHereNetwork/PHNSurvey/PHNSurvey'))
    }, {
        optionCode: 'PHNBANNER',
        OptionComponent: lazy(() => import('../PayHereNetwork/PHNBanner/PHNBanner'))
    }, {
        optionCode: 'PHNRECEIPT',
        OptionComponent: lazy(() => import('../PayHereNetwork/PHNReceipt/PHNReceipt'))
    }, {
        optionCode: 'PHNMTUBLRS',
        OptionComponent: lazy(() => import('../PayHereNetwork/PHNMTUBillers/PHNMTUBillers'))
    }, {
        optionCode: 'YAKBACK',
        OptionComponent: lazy(() => import('../PayHereNetwork/YakBack/YakBack'))
    }]
}, {
    CategoryIcon: AllInclusive,
    categoryName: 'SR Tools',
    categoryOptions: [{
        optionCode: 'MEMOAGTBP',
        OptionComponent: lazy(() => import('../Agent/AgentBillPay/AgentBillPay'))
    }, {
        optionCode: 'MAXIAGTBPR',
        OptionComponent: lazy(() => import('../SR/BillPayRejects/BillPayRejects'))
    }, {
        optionCode: 'MEMOAGTBPR',
        OptionComponent: lazy(() => import('../SR/BillPayRejects/BillPayRejects'))
    }, {
        optionCode: 'MEMOSYSADM',
        OptionComponent: lazy(() => import('../SR/MemoSysAdmin/MemoSysAdmin'))
    }, {
        optionCode: 'MAXISYSADM',
        OptionComponent: lazy(() => import('../SR/MaxiSysAdmin/MaxiSysAdmin'))
    }, {
        optionCode: 'FSRVSYSADM',
        OptionComponent: lazy(() => import('../SR/FiservSysAdmin/FiservSysAdmin'))
    }]
}, {
    CategoryIcon: ShowChart,
    categoryName: 'Monitoring Tools',
    categoryOptions: [{
        optionCode: 'STAT1',
        OptionComponent: lazy(() => import('../Monitoring/Transactions/Transactions'))
    }, {
        optionCode: 'BPMOFUNDS',
        OptionComponent: lazy(() => import('../Monitoring/DashboardFunds/DashboardFunds'))
    }, {
        optionCode: 'MTDYTDCOMP',
        OptionComponent: lazy(() => import('../Monitoring/DashboardMTDYTD/DashboardMTDYTD'))
    }, {
        optionCode: 'BPMOTOTCGI',
        OptionComponent: lazy(() => import('../Monitoring/DashboardBPMO/DashboardBPMO'))
    }, {
        optionCode: 'SHOWANLY',
        OptionComponent: lazy(() => import('../Monitoring/Analytics/Analytics'))
    }]
}, {
    CategoryIcon: Web,
    categoryName: 'Biller Maintenance',
    categoryOptions: [{
        optionCode: 'MTNBLRS',
        OptionComponent: lazy(() => import('../BillerMaintenance/MaintainBillers/MaintainBillers'))
    }, {
        optionCode: 'JHREJECT',
        OptionComponent: lazy(() => import('../BillerMaintenance/JHRejects/JHRejects'))
    }, {
        optionCode: 'BUSRULES',
        OptionComponent: lazy(() => import('../BillerMaintenance/BusinessRules/BusinessRules'))
    }, {
        optionCode: 'BUSRULESCE',
        OptionComponent: lazy(() => import('../BillerMaintenance/BusinessRules/BusinessRules'))
    }, {
        optionCode: 'WEBBLRSLS',
        OptionComponent: lazy(() => import('../BillerMaintenance/BillerSales/BillerSales'))
    }]
}, {
    CategoryIcon: BuildOutlined,
    categoryName: 'Web Maintenance',
    categoryOptions: [{
        optionCode: 'MUSER',
        OptionComponent: lazy(() => import('../Maintenance/MaintainWebUsers/MaintainWebUsers'))
    }, {
        optionCode: 'MUSERBAT',
        OptionComponent: lazy(() => import('../Maintenance/MaintainWebUsersBatch/MaintainWebUsersBatch'))
    }, {
        optionCode: 'MUSERLMT',
        OptionComponent: lazy(() => import('../Maintenance/MaintainWebUsersLimited/MaintainWebUsersLimited'))
    }, {
        optionCode: 'CHGAGT',
        OptionComponent: lazy(() => import('../Maintenance/ChangeAgent/ChangeAgent'))
    }, {
        optionCode: 'UPDPRF',
        OptionComponent: lazy(() => import('../Maintenance/UpdateProfile/UpdateProfile'))
    }]
}, {
    CategoryIcon: BuildOutlined,
    categoryName: 'Brenham',
    categoryOptions: [{
        optionCode: 'BRENHAMACH',
        OptionComponent: lazy(() => import('../Brenham/ACH/ACH'))
    }]
}, {
    CategoryIcon: MoneyOff,
    categoryName: 'ACH',
    categoryOptions: [{
        optionCode: 'ACH',
        OptionComponent: lazy(() => import('../ACH/ACH'))
    }]
}];

export const implementedOptionMap = new Map<string, LazyExoticComponent<() => JSX.Element>>(
    optionCategories.map(({ categoryOptions }) =>
        categoryOptions
    ).flat().map(({ optionCode, OptionComponent }) =>
        [optionCode, OptionComponent]
    )
);

const useStyles = makeStyles((theme) => ({
    navigator: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary
    }
}));

function Navigator() {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

    const [AMLDialogOpen, setAMLDialogOpen] = useState(false);
    const [contactDialogOpen, setContactDialogOpen] = useState(false);
    const [desktopDrawerOpen, setDesktopDrawerOpen] = useState(true);
    const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
    const { userData, needsProfileUpdate, needsUserAgreement } = useContext(AuthContext);
    const { pathname } = useLocation();
    const firstSegment = pathname.split('/')[1] ?? '';

    const classes = useStyles();

    const completeOptions = useMemo(() =>
        userData?.options?.filter(({ optionCode }) =>
            implementedOptionMap.has(optionCode)
        ).map((option) => {
            const OptionComponent = implementedOptionMap.get(option.optionCode)!;
            return {
                OptionComponent,
                ...option
            }
        }) ?? []
        , [userData?.options])

    const completeOptionMap = useMemo(() =>
        new Map<string, CompleteOption>(
            completeOptions.map((option) =>
                [option.optionCode, option]
            ))
        , [completeOptions])

    const completeOptionCategories = useMemo(() =>
        optionCategories.map(({ categoryName, categoryOptions, CategoryIcon }) => ({
            CategoryIcon,
            categoryName,
            categoryOptions: categoryOptions.filter(({ optionCode }) =>
                completeOptionMap.has(optionCode)
            ).map(({ optionCode }) =>
                completeOptionMap.get(optionCode)!
            )
        })).filter(({ categoryOptions }) =>
            categoryOptions.length > 0
        )
        , [completeOptionMap]);

    const drawerOpen = isMobile ? mobileDrawerOpen : desktopDrawerOpen;

    const setDrawerOpen = useCallback((drawerOpen: boolean) => {
        if (isMobile) {
            setMobileDrawerOpen(drawerOpen);
        } else {
            setDesktopDrawerOpen(drawerOpen);
        }
    }, [isMobile]);

    if (userData && (needsProfileUpdate || needsUserAgreement) && firstSegment !== 'UPDPRF') {
        return <Redirect to='/UPDPRF' />
    } else if (userData && ['Login', ''].includes(firstSegment)) {
        return <Redirect to='/Dashboard' />
    } else if (!userData && !['Login', 'Referral', 'OptIn', 'O', 'TermsOfUse'].includes(firstSegment)) {
        return <Redirect to='/Login' />
    } else {
        return (
            <Box display='flex' className={classes.navigator}>
                <OptionDrawer open={drawerOpen} setDrawerOpen={setDrawerOpen} optionCategories={completeOptionCategories} />
                <Hidden smDown={mobileDrawerOpen}>
                    <AppHeader open={drawerOpen} setDrawerOpen={setDrawerOpen} />
                    <main>
                        <Suspense fallback={<Loading />}>
                            <Switch>
                                <Route path={['/OptIn', '/O']}><OptIn /></Route>
                                <Route path='/Referral'><Referral /></Route>
                                <Route path='/TermsOfUse'><TermsOfUse /></Route>
                                <Route path='/Dashboard' ><Dashboard optionCategories={completeOptionCategories} /></Route>
                                {completeOptions.map(({ optionCode, OptionComponent }) =>
                                    <Route key={optionCode} path={`/${optionCode}`} ><OptionComponent /></Route>
                                )}
                                <Route path={['/Login']} ><Login setAMLDialogOpen={setAMLDialogOpen} setContactDialogOpen={setContactDialogOpen} /></Route>
                            </Switch>
                        </Suspense>
                    </main>
                    <AMLDialog open={AMLDialogOpen} setOpen={setAMLDialogOpen} />
                    <ContactDialog open={contactDialogOpen} setOpen={setContactDialogOpen} />
                </Hidden>
            </Box>
        );
    }
}

export default Navigator;