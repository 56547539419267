import { Grid, IconButton, Tooltip, Divider } from '@material-ui/core';
import { ArrowForward, ArrowBack, ExpandLess } from '@material-ui/icons';
import { Preview, Detail } from './Notifications';

interface Props {
    detail: Detail,
    setDetail: (detail: null | Detail) => void,
    getDetail: (preview: Preview) => Promise<void>,
    previews: Preview[]
}

export default function NotificationDetail({ detail, setDetail, getDetail, previews }: Props) {

    const currentIndex = previews.findIndex(({ id }) =>
        id === detail.id
    );

    const clearDetail = () =>
        setDetail(null);

    const nextNotification = previews[currentIndex + 1];

    const goForward = () =>
        getDetail(nextNotification);

    const previousNotification = previews[currentIndex - 1];

    const goBack = () =>
        getDetail(previousNotification);

    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: detail.notification }} />
            <Divider />
            <Grid container spacing={3} >
                <Grid item xs={4} container justifyContent='center' >
                    {previousNotification &&
                        <Tooltip title='Previous Message'>
                            <IconButton onClick={goBack}>
                                <ArrowBack />
                            </IconButton>
                        </Tooltip>
                    }
                </Grid>
                <Grid item xs={4} container justifyContent='center'>
                    <Tooltip title='Previews'>
                        <IconButton onClick={clearDetail}>
                            <ExpandLess />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={4} container justifyContent='center' >
                    {nextNotification &&
                        <Tooltip title='Next Message'>
                            <IconButton onClick={goForward}>
                                <ArrowForward />
                            </IconButton>
                        </Tooltip>
                    }
                </Grid>
            </Grid>
        </>
    );
}