import { ListItem, ListItemText, List } from '@material-ui/core';
import { parse, format } from 'date-fns';
import { Preview } from './Notifications';

const getFormattedTimestamp = (timestamp: string) => {
    const parsedDate = parse(timestamp, 'yyyy-MM-dd HH:mm:ss.SSSSSS', new Date());
    return format(parsedDate, 'MMMM do yyyy, h:mm:ss a');
}

interface Props {
    previews: Preview[],
    getDetail: (preview: Preview) => Promise<void>
}

export default function NotificationList({ previews, getDetail }: Props) {
    return (
        <List>
            {previews.map(({ id, preview, sentTime }) =>
                <ListItem key={id} button onClick={() => { getDetail({ id, preview, sentTime }) }} >
                    <ListItemText primary={preview} secondary={getFormattedTimestamp(sentTime)} />
                </ListItem>
            )}
        </List>
    )
}